<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="jobData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this job no. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-job-list'}"
        >
          Job List
        </b-link>
        for other jobs.
      </div>
    </b-alert>

    <b-row
      v-if="jobData"
      class="invoice-add"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                    <h3 class="text-primary invoice-logo">
                      {{jobData.job_customer_name}}
                      ({{jobData.job_customer_code}})
                    </h3>
                  </div>
                  <b-card-text>
                    {{jobData.job_customer_contact_name}}
                      ({{jobData.job_customer_contact_no}})
                  </b-card-text>
                  <b-card-text class="mb-25">
                    {{jobData.job_address}}
                  </b-card-text>
                  <b-card-text class="mb-25">
                    {{jobData.job_postal_code}}
                  </b-card-text>
                 
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Created Date: {{moment(jobData.job_created_at).format("DD MMM yyyy")}}
                    </span>
                  </div>
                  <div class="d-flex align-items-center" style="display:none;">
                    <span class="title">
                      Created By: {{getNameById(jobData.job_created_by)}}
                    </span> 
                  </div>
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Job No
                    </h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="job-data-job-no"
                        v-model="jobData.job_no"
                        disabled
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Quotation No
                    </h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="job-data-job-no"
                        v-model="jobData.job_quotation_no"
                        disabled
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center justify-content-md-end mb-1"> 
                 
                 <span class="title">
                   Sales Agent:
                 </span>
                 <b-form-input v-model="jobData.job_sales_agent" ></b-form-input>
                 
               </div>
                  <div class="d-flex align-items-center justify-content-md-end mb-1"> 
                 
                    <span class="title">
                      Status:
                    </span>
                    <b-form-select
                      v-model="jobData.job_status"
                      :options="['Active','Pending','Cancelled','On Hold','Completed','Pending Payment','Closed']" 
                    />  
                    
                  </div>
                  <div class="d-flex align-items-center justify-content-md-end mb-1"> 
                    <span class="title">
                      Start Date:
                    </span>
                    <b-form-datepicker v-model="jobData.job_start_date"
                      :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                      ></b-form-datepicker> 
                  </div>
                  <div class="d-flex align-items-center justify-content-md-end mb-1"> 
                    <span class="title">
                      End Date:
                    </span>
                    <b-form-datepicker v-model="jobData.job_end_date"
                      :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                      ></b-form-datepicker> 
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{height: trHeight}"
              >
                <b-row
                  v-for="(item, index) in jobData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >

                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">

                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="5"
                        >
                          Item
                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Cost
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Qty
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Price
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="5"
                        >
                          <label class="d-inline d-lg-none">Item</label>
                          <v-select
                            v-model="item.itemTitle"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="itemsOptions"
                            label="itemTitle"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            @input="val => updateItemForm(index, val)"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Cost</label>
                          <b-form-input
                            v-model="item.cost"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Qty</label>
                          <b-form-input
                            v-model="item.qty"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Price</label>
                          <p class="mb-1">
                            ${{ item.cost * item.qty }}
                          </p>
                        </b-col>
                        <b-col
                          cols="12"
                          lg="5"
                        >
                          <label class="d-inline d-lg-none">Description</label>
                          <b-form-textarea
                            v-model="item.description"
                            class="mb-2 mb-lg-0"
                          />
                        </b-col>
                        <b-col>
                          <p class="mb-0">
                            Discount: 0% 0% 0%
                          </p>
                        </b-col>
                      </b-row>
                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />
                        <feather-icon
                          :id="`form-item-settings-icon-${index}`"
                          size="16"
                          icon="SettingsIcon"
                          class="cursor-pointer"
                        />

                        <!-- Setting Item Form -->
                        <b-popover
                          :ref="`form-item-settings-popover-${index}`"
                          :target="`form-item-settings-icon-${index}`"
                          triggers="click"
                          placement="lefttop"
                        >
                          <b-form @submit.prevent>
                            <b-row>

                              <!-- Field: Discount -->
                              <b-col cols="12">
                                <b-form-group
                                  label="Discount(%)"
                                  :label-for="`setting-item-${index}-discount`"
                                >
                                  <b-form-input
                                    :id="`setting-item-${index}-discount`"
                                    :value="null"
                                    type="number"
                                  />
                                </b-form-group>
                              </b-col>

                              <!-- Field: Tax 1 -->
                              <b-col cols="6">
                                <b-form-group
                                  label="Tax 1"
                                  :label-for="`setting-item-${index}-tax-1`"
                                >
                                  <v-select
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :value="'10%'"
                                    :options="['0%', '1%', '10%', '14%', '18%']"
                                    :input-id="`setting-item-${index}-tax-1`"
                                    :clearable="false"
                                  />
                                </b-form-group>
                              </b-col>

                              <!-- Field: Tax 2 -->
                              <b-col cols="6">
                                <b-form-group
                                  label="Tax 2"
                                  :label-for="`setting-item-${index}-tax-2`"
                                >
                                  <v-select
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :value="'10%'"
                                    :options="['0%', '1%', '10%', '14%', '18%']"
                                    :input-id="`setting-item-${index}-tax-2`"
                                    :clearable="false"
                                  />
                                </b-form-group>
                              </b-col>

                              <b-col
                                cols="12"
                                class="d-flex justify-content-between mt-1"
                              >
                                <b-button
                                  variant="outline-primary"
                                  @click="() => {$refs[`form-item-settings-popover-${index}`][0].$emit('close')}"
                                >
                                  Apply
                                </b-button>
                                <b-button
                                  variant="outline-secondary"
                                  @click="() => {$refs[`form-item-settings-popover-${index}`][0].$emit('close')}"
                                >
                                  Cancel
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-form>
                        </b-popover>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div> 
              <div>
                <h2>Quoted Item:<button class="btn mr-2 btn-warning" @click="addJobTasks()">Add all to task </button></h2>
                <ul v-if="jobQuoteData !=undefined && jobQuoteData.length > 0">
                  <li v-for="jobQuote in jobQuoteData" v-bind:key="jobQuote.dtlKey" style="margin:10px 0px;">
                    {{ jobQuote.description }} 
                    <button class="btn mr-2 btn-warning" @click="addJobTask(jobQuote)">Add to task </button>
                  </li>
                </ul>
              </div>
              <hr />
                <span class="font-weight-bold">Note: </span>
              <b-form-textarea v-model="jobData.job_remark" />
              <div class="d-flex mt-2 justify-content-end">
                <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="updateJob()"
            >
              Save
            </b-button>
              </div>
              
            </b-card-body>
 

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              
              <b-tabs content-class="mt-1">
    <!-- This tabs content will always be mounted -->
    <b-tab title="Task" lazy>
      <div class="todo-application">
        <div class="content-area-wrapper">
         
          <div class="content-right" style="width:100%;">
            <todo 
            :job_id="jobData.job_id"
            :job_end_date="jobData.job_end_date" 
            @update-job="updateJobEndDate"
            />
            
          </div>
          </div>

      </div>
      
    </b-tab>

    <!-- This tabs content will not be mounted until the tab is shown -->
    <!-- and will be un-mounted when hidden -->
    <!-- b-tab
      title="Documents"
      lazy 
    > 
        <div >
          Document inventory?
        </div> 
    </b-tab -->
    
 
  </b-tabs>
            </b-card-body>
          </b-card>
        </b-form>
        
      </b-col> 
    </b-row>
  
  </section>
  
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router'
import {
  BFormDatepicker,BFormSelect,BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, BAlert, BLink, VBToggle,
BTabs, BTab, } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import todo from '../../todo/Todo.vue'
import todoLeftSidebar from '../../todo/TodoLeftSidebar.vue'
import jobStoreModule from '../jobStoreModule'
import userStoreModule from '../../user/userStoreModule' 
import moment from 'moment';
export default {
  components: {
    todo,
    moment,
    todoLeftSidebar,
    BFormSelect,
    BFormDatepicker,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    BTabs, BTab, 
    flatPickr,
    vSelect,
    Logo,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'jobData.items': function () {
      this.initTrHeight()
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    moment,
    addJobTasks(){
      for(let i=0;i<this.jobQuoteData.length;i++)
      {
        let jobTask = {};
        let lineItem = this.jobQuoteData[i];
        jobTask.job_task_description = lineItem.description;
        jobTask.job_task_remark = "";
        jobTask.job_task_job_id = this.jobData.job_id;

        store.dispatch('app-job/addJobTask', jobTask).then(response => {});
      }
      location.reload();
    },
    addJobTask(lineItem){ 
      let jobTask = {};
      jobTask.job_task_description = lineItem.description;
      jobTask.job_task_remark = "";
      jobTask.job_task_job_id = this.jobData.job_id;
      store.dispatch('app-job/addJobTask', jobTask)
      .then(response => {
        this.$swal({
                icon: 'success',
                title: 'Saved!',
                text: 'Record has been updated.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(function(){ 
                location.reload();
                }
              );
      })
      .catch(error => {
        if (error.response.status === 404) {
          jobData.value = undefined
        }
      })
    },
    updateJobEndDate(returnObj)
    {
        this.jobData.job_end_date = returnObj.job_task_ex_project_date;
        this.updateJob();
    },
    updateJob()
    {
      store.dispatch('app-job/updateJob', this.jobData)
      .then(response => {
        this.$swal({
                icon: 'success',
                title: 'Saved!',
                text: 'Record has been updated.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
      })
      .catch(error => {
        if (error.response.status === 404) {
          jobData.value = undefined
        }
      })
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.jobData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.jobData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
      })
    },
    
    
  },
  setup() {
    const JOB_APP_STORE_MODULE_NAME = 'app-job'
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    
    // Register module
    if (!store.hasModule(JOB_APP_STORE_MODULE_NAME)) store.registerModule(JOB_APP_STORE_MODULE_NAME, jobStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(JOB_APP_STORE_MODULE_NAME)) store.unregisterModule(JOB_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const jobData = ref(null);
    const jobQuoteData = ref(null);
    const workerList = ref([]);

    const getNameById = (id) =>{
      var worker = workerList.value.filter(x => x.user_id == id);
      return worker[0].user_name;
    };
    store.dispatch('app-job/fetchJob', { id: router.currentRoute.params.id })
      .then(response => {
        
        jobData.value = response.data;
        
          store.dispatch('app-job/fetchQuoteDetails', { job_entity_name: jobData.value.job_entity_name, job_quotation_no: jobData.value.job_quotation_no })
          .then(response2 => {
            jobQuoteData.value = response2.data;
          })
          .catch(error => {
            if (error.response.status === 404) {
              jobData.value = undefined
            }
          })
      })
      .catch(error => {
        if (error.response.status === 404) {
          jobData.value = undefined
        }
      });
      
      store.dispatch('app-user/fetchUsers').then((response)=>{
          
          if(response.data != undefined)
          {  
            workerList.value = response.data;
          }
        });
    return {
      jobData,
      jobQuoteData,
      workerList,
      getNameById
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/pages/app-todo.scss";
.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
